.apploader {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  height: 128px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;

  &__bar {
    width: 8px;
    background: #fff;
    transform-origin: bottom center;
    border-radius: 16px;
    animation: loader 1.2s linear infinite;
    
    @for $i from 1 through 18 {
      &:nth-child(#{$i}) {
        animation-delay: #{calc($i / 15)}s;
      }
    }
  }
}

@keyframes loader {
  0% {
    height: 8px;
    background: #e6fffb;
  }
  50% {
    height: 48px;
    background: #096dd9;
  }
  100% {
    height: 8px;
    background: #e6fffb;
  }
}
