// @import '../node_modules/antd/dist/antd.min.css';
@import '../node_modules/antd/dist/reset.css';

html {
  scroll-behavior: smooth;
  min-height: 100%;
  padding: 0;
  margin: 0;
}

body {
  background-color: #f1f2f5;
}

.ant-layout-header {
  padding: 0 24px;
  position: fixed;
  z-index: 1000;
  width: 100%;
  display: flex;
}

.ant-page-header {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.axis__wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  .axis {
    position: relative;
    display: inline-block;
    background: url(./axis-t.png) 0 0 no-repeat;

    &.axis--car {
      background-image: url(./axis-c.png);
    }

    //  Original: 208 × 572
    //  1 / 2   : 104 × 286
    //  1 / 2.6 : 80 × 220
    background-size: 80px 220px;
    width: 170px;
    height: 240px;

    &__label {
      position: absolute;
      top: 12px;
      text-align: center;
      width: 80px;
    }

    &__group {
      position: absolute;
      z-index: 1000;
      top: 230px;
      height: 5px;
      left: 15px;
      background: #ddd;
      border-radius: 5px;

      &-0 {
        display: none;
      }

      &-1 {
        width: 50px;
      }

      &-2 {
        width: 220px;
      }

      &-3 {
        width: 390px;
      }
    }

    &__errors {
      position: absolute;
      top: 165px;
      width: 170px;
      white-space: normal;

      .ant-row {
        display: block;
        margin: 0;
        .ant-form-item-control-input {
          display: none;
        }
      }
    }

    &__distance {
      position: absolute;
      left: 100px;
      top: 95px;
      width: 70px;
    }

    .ant-checkbox-wrapper {
      position: absolute;
      left: 60px;
      & + .ant-checkbox-wrapper {
        margin: 0;
      }
    }

    &__check-e {
      top: 55px;
    }
    &__check-p {
      top: 85px;
    }
    &__check-t {
      top: 115px;
    }
    &__check-o {
      top: 145px;
    }
  }
}

.axis__legend {
  width: 400px;
  dl {
    display: flex;
    flex-wrap: wrap;

    dt,
    dd {
      flex-direction: row;
      margin: 0;
    }

    .group {
      margin-top: 0.5rem;
    }

    dt {
      flex: 0 0 30%;
      font-weight: normal;
    }

    dd {
      flex: 0 0 70%;
      position: relative;
      &::before {
        display: block;
        content: '-';
        position: absolute;
        left: -12px;
      }
    }
  }
}

.ant-steps-item {
  text-align: left !important;
  padding-left: 12px !important;
  margin-right: 20px !important;
}

.ant-picker,
.ant-input-number {
  width: 100%;
}

.ant-form-item {
  margin-bottom: 12px;
}

@import '../node_modules/react-phone-number-input/style.css';

.PhoneInputInput {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 4px 11px;
  transition: all 0.3s;

  &:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }

  &:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: 0;
  }
}
